<template>
  <div>

    <div class="row mt-5 justify-content-center">
      <div class="col-4">
        <form @submit.prevent="register" novalidate>
          <input type="text" placeholder="Nome da empresa" v-model="company" class="form-control" name="text" v-validate="'required'"/>
          <input type="text" placeholder="Seu nome" v-model="name" class="form-control mt-2" name="text" v-validate="'required'"/>
          <input type="text" placeholder="E-mail" v-model="email" class="form-control mt-2" name="email" v-validate="'required|email'"/>
          <input type="password" placeholder="Senha" v-model="password" class="form-control mt-2" name="password" v-validate="'required'"/>

          <input type="submit" value="Login" class="btn btn-primary mt-2" />
        </form>
      </div>
    </div>
    
  </div>
</template>
<script>
import CompanyService from "@/services/resources/CompanyService";
import UserService from "@/services/resources/UserService";
const serviceCompany = CompanyService.build();
const serviceUser = UserService.build();

export default {
  data() {
    return {
      company: null,
      name: null,
      email: null,
      password: null
    };
  },
  methods: {
    register(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          
          let data = {
            name: this.company
          }


          serviceCompany.create(data)
          .then(resp => {
            console.log(resp)

            data = {
              company_id: resp.id,
              name: this.name,
              email: this.email,
              password: this.password
            }

            serviceUser.create(data)
            .then(resp => {
              
              data = {
                username: this.email,
                password: this.password
              }

               this.$store
                .dispatch("loginRequest", data)
                .then(() => {
                  this.$router.push("/dashboard");
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  this.success = false;
                  this.submit = false;
                  this.loading = false;
                });

            }).catch(err => {
              console.log(err)
            })
          }).catch(err => {
            console.log(err)
          });

          
        }
      });
    }

  },
  mounted() {
   
  },
};
</script>
<style scoped lang="scss">
</style>